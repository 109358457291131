exports.components = {
  "component---src-pages-401-js": () => import("./../../../src/pages/401.js" /* webpackChunkName: "component---src-pages-401-js" */),
  "component---src-pages-403-js": () => import("./../../../src/pages/403.js" /* webpackChunkName: "component---src-pages-403-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-add-to-discord-js": () => import("./../../../src/pages/add-to-discord.js" /* webpackChunkName: "component---src-pages-add-to-discord-js" */),
  "component---src-pages-contributors-js": () => import("./../../../src/pages/contributors.js" /* webpackChunkName: "component---src-pages-contributors-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard/[...].js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pay-to-play-js": () => import("./../../../src/pages/pay-to-play.js" /* webpackChunkName: "component---src-pages-pay-to-play-js" */),
  "component---src-pages-premium-js": () => import("./../../../src/pages/premium.js" /* webpackChunkName: "component---src-pages-premium-js" */),
  "component---src-pages-premium-plus-js": () => import("./../../../src/pages/premium-plus.js" /* webpackChunkName: "component---src-pages-premium-plus-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog/index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-post-index-js-content-file-path-data-blog-posts-championships-mdx": () => import("./../../../src/templates/blog-post/index.js?__contentFilePath=/build-directory/data/blog-posts/championships.mdx" /* webpackChunkName: "component---src-templates-blog-post-index-js-content-file-path-data-blog-posts-championships-mdx" */),
  "component---src-templates-blog-post-index-js-content-file-path-data-blog-posts-create-a-championship-mdx": () => import("./../../../src/templates/blog-post/index.js?__contentFilePath=/build-directory/data/blog-posts/create-a-championship.mdx" /* webpackChunkName: "component---src-templates-blog-post-index-js-content-file-path-data-blog-posts-create-a-championship-mdx" */),
  "component---src-templates-blog-post-index-js-content-file-path-data-blog-posts-leagues-mdx": () => import("./../../../src/templates/blog-post/index.js?__contentFilePath=/build-directory/data/blog-posts/leagues.mdx" /* webpackChunkName: "component---src-templates-blog-post-index-js-content-file-path-data-blog-posts-leagues-mdx" */),
  "component---src-templates-blog-post-index-js-content-file-path-data-blog-posts-tournaments-mdx": () => import("./../../../src/templates/blog-post/index.js?__contentFilePath=/build-directory/data/blog-posts/tournaments.mdx" /* webpackChunkName: "component---src-templates-blog-post-index-js-content-file-path-data-blog-posts-tournaments-mdx" */)
}

